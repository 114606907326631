























































import {Component, Vue, Watch, Prop, Emit} from 'vue-property-decorator';
    
    import DropDown from "@/components/common/Inputs/DropDown.vue";
    import Navbar from '@/components/common/Navbar.vue';
    import Illustration from '@/components/common/Illustration.vue';
    import Snackbar from '@/components/common/Snackbar.vue'
    import ListItem from '@/components/common/List/ListItem.vue'
    import ListItemContent from '@/components/common/List/ListItemContent.vue'
    import DetailsCard from "@/components/common/DetailsCard.vue";
    import ActionablePopup from '@/components/common/Popup/ActionablePopup.vue'
    import Popup from '@/components/common/Popup/Popup.vue'
    import DataTable from '@/components/common/Tables/DataTable.vue'
    import TextButton from '@/components/common/Buttons/TextButton.vue'
    import {ILink, ITableHeader} from '@/interfaces/common'
    import {ICompetitorInfo,PCSKUMappings} from '@/interfaces/CompetitorSurveyManagement'
    import { colors, VLayout } from 'vuetify/lib';
    import CompetitorView from '@/components/CompetitorSurveyManagement/CompetitorView.vue'
    import CompanySettingService from "@/services/CompanySettingServics";
    import router from '@/router/routes';
    import CompetitorSurveyManagementService from '@/services/CompetitorSurveyManagementService';
    import { PrimaryCategory } from '@/Enum/CompetitorSurveyManagement';

    @Component({
        components: {
            Illustration,
            Navbar,
            Snackbar,
            DetailsCard,
            DropDown,
            ActionablePopup,
            Popup,
            ListItem,
            ListItemContent,
            DataTable,
            TextButton,
            CompetitorView

        }
    })
    export default class ManageCompetitorHeader extends Vue{
        private totalCompetitor:number=0;
        private menu: Array<ILink>=[
        {
            Text: "Deactivate",
            Color: "red"
        }
    ];
    private inactiveCompetitors:boolean=false;
    private CompetitorList: Array<ICompetitorInfo>=[]   
    private List:Array<ICompetitorInfo>=[]  
    private createCompetitor:boolean=false;
    private selectedId:any=0
    private Manage:boolean=false;
    private ToManage:boolean=false;
     
    private CompetitorInfo:any={}

    private description: string = "No Competitors Found" 
    private subdescription: string = "Please create a Competitor to begin with"
    private pages: number = 0
    private page: number = 1
    private data:any={}
    private warningPopup: boolean = false
    private warningPopupMsg: string = ""
    private confirmationPopup: boolean = false
    private confirmationPopupTitle: string = ""
    private confirmationPopupSubtitle: string = ""
    private confirmationPopupText: string = ""
    private cancelBtnText: string = ""
    private confirmBtnText: string = ""
    private confirmPopupTitle:string=""
    private confirmPopupSubtitle:string=""
    private confirmPopupText:string=""
    private snackbar:boolean=false
    private snackbarcolor: string='success'
    private message:string=''
    private toviewCompetitor:boolean=false;
      private PrimaryCategory: Array<any> = [
    { key: 'Primary_Category_one', value: 'Primary Category One' },
    { key: "Primary_Category_two", value: "Primary Category Two" },
    { key: "Primary_Category_three", value: "Primary Category Three" },
    { key: "Primary_Category_four", value: "Primary Category Four" },
    
  ]
    private tableHeaders: Array<ITableHeader> = [
        {
                text: 'Competitors',
                value: 'CompetitorName',
                sortable: true,
                default: ''
            },
            
            {
                text: 'Actions',
                value: 'action',
                sortable: false,
                default: ''
            }
        ]
        public menuActions(Competitor:ICompetitorInfo, Index:number){
        this.selectedId=Competitor.CompetitorId;
        switch("Deativate"){
            case "Deativate":
                this.confirmPopupTitle="Confirm Deactivation";
                this.confirmPopupSubtitle="The competitor will be deactivated";
                this.cancelBtnText="KEEP ACTIVE";
                this.confirmBtnText="DEACTIVATE";
                this.confirmationPopup=true;
                break;
        }
    }
    navigateToCreate(){
    
        router.push("/CompetitorSurveyManagement/Add")
        
    }
    public sdata:Array<ICompetitorInfo>=[];
    public competitorName:any;
    toView(item:ICompetitorInfo){
        this.toviewCompetitor=true;
        this.competitorName=item.CompetitorName;
        this.getPCSKUMappings(item.CompetitorId);
    }
    public confirmAction(value:any):void{
        CompetitorSurveyManagementService.DeactivateCompetitor(value).then((res) =>{
            this.snackbarcolor="red";
            this.message="Competitor Deactivated.";
            this.actionablePopup();
            this.refreshCompetitorList();
            
        }).catch((err)=> {
            console.log(err);
        })

    }
    public async refreshCompetitorList(){
        this.inactiveCompetitors? await this.getInactiveCompetitors(): await this.getActiveCompetitors();
    }
     public closeSnackbar(value: boolean): void{
        this.snackbar = value;
    }
        public check:boolean=false;
    public switchCompetitor(){
        if(this.inactiveCompetitors==true){
            this.getInactiveCompetitors();
            this.check=false;
        }
        else{
            this.getActiveCompetitors();
            this.check=true
        }
       
    }
    Back()
    {
        this.toviewCompetitor=false;
    }
    public mappingModel:Array<PCSKUMappings>=[];
    public async getPCSKUMappings(Id:string):Promise<boolean>{
        let that=this;
        return new Promise<boolean>(res=>{
            CompetitorSurveyManagementService.GetSKUMappings(Id)
            .then((response:any)=>{
                that.mappingModel=[];
                that.data=response.data;
                response.data.forEach((element:any,index:number)=>{
                    var item:PCSKUMappings={
                        Skus:"",
                        PC:""
                    };
                    item.PC=element.pcName;
                    item.Skus=element.skUs;
                    that.mappingModel.push(item);

                    res(true);
                });

            })
            .catch((err:any)=>{
                console.log(err);
            })
        })
    }
     public async getActiveCompetitors():Promise<boolean>{
        let that=this;
        that.totalCompetitor=0;
        return new Promise<boolean>(res =>{
        CompetitorSurveyManagementService.GetCompetitorList(true)
        .then((response:any) => {
          that.CompetitorList=[];
          response.data.forEach((element:any, index: number)=>{
              var item: ICompetitorInfo={
                  
                  CompetitorId: "",
                  CompetitorName: "",
                  PrimaryCategory: PrimaryCategory.Primary_Category_one,
                  AddProductToPC:""


              };
              item.CompetitorId=element.id;
              item.CompetitorName = element.name;
              item.PrimaryCategory = element.PrimaryCategory;
              that.CompetitorList.push(item)
              
              res(true);
          });
      })
      .catch((err:any) => {
        console.log(err);
      });
    })
    }
     public async getInactiveCompetitors():Promise<boolean>{
        let that=this;
        that.totalCompetitor=0;
        return new Promise<boolean>(res =>{
        CompetitorSurveyManagementService.GetCompetitorList(false)
        .then((response:any) => {
          that.CompetitorList=[];
          response.data.forEach((element:any, index: number)=>{
              var item: ICompetitorInfo={
                  CompetitorId:"",
                  CompetitorName: "",
                  PrimaryCategory:  PrimaryCategory.Primary_Category_one,
                  AddProductToPC:""


              };
              item.CompetitorId=element.id;
              item.CompetitorName = element.name;
              item.PrimaryCategory = element.PrimaryCategory;
              that.CompetitorList.push(item);
              
              res(true);
          });
      })
        .catch((err:any) => {
        console.log(err);
      });
    })
    }


    public actionablePopup(){
        this.snackbar = true;
        this.confirmationPopup = false;
        }

    public async GetCompetitorList(status:boolean):Promise<boolean>{
        let that=this;
        that.totalCompetitor=0;
        return new Promise<boolean>(res=>{
            CompetitorSurveyManagementService.GetCompetitorList(status)
            .then((response:any)=>{
                that.CompetitorList=[];
                that.data=response.data;
                response.data.forEach((element:any, index:number)=>{
                    var item:ICompetitorInfo={
                        CompetitorName: "",
                        PrimaryCategory: PrimaryCategory.Primary_Category_one,
                        CompetitorId: "",
                        AddProductToPC:""


                    };
                    item.CompetitorName=element.name,
                    item.CompetitorId=element.id,
                    that.CompetitorList.push(item),
                    //that.sdata.push(item.CompetitorName);
                    res(true);
                });
            })
            .catch((err:any)=>{
                console.log(err);
            })
        })
    }
    mounted(){
        this.getActiveCompetitors()

    }

}
