import client from './../apiService';
import { ICompetitorInfo ,prod} from "@/interfaces/CompetitorSurveyManagement";
import {EntityMinWithIds} from "@/interfaces/EntityMin"

class CompetitorSurveyManagementService{
    GetCompetitorList(active:boolean){
        const url=  `Competitor/GetCompetitorList?active=`+active;
        return client.get(url,{
            headers: {
                'Content-Type': 'application/json'
            }          
        });
    }
    Add(data:prod){
        const url= `Competitor/Add`;
        return client.post(url,data,{
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    UpdateCompetitor(competitorId:string,name:string){
        const url=`Competitor/UpdateCompetitor?competitorId=${competitorId}&&name=${name}`;
        return client.post(url,{
            headers:{
                'Content-Type':'application/json'
            }
        });
    }
    DeactivateCompetitor(competitorId:number){
        const url=`Competitor/DeactivateCompetitor?competitorId=${competitorId}`;
        return client.post(url,{
            headers:{
                'Content-Type':'application/json'
            }            
        });
    }
    ActivateCompetitor(competitorId:number){
        const url=`Competitor/ActivateCompetitor?competitorId=${competitorId}`;
        return client.post(url,{
            headers:{
                'Content-Type':'application/json'
            }            
        });
    }
    GetSKUMappings(competitorId:string){
        const url=`Competitor/GetSKUMappingById?competitorId=${competitorId}`;
        return client.get(url,{
            headers:{
                'Content-Type':'application/json'
            }            
        });
    }
}

export default new CompetitorSurveyManagementService();