export enum PrimaryCategory{
    Primary_Category_one,
    Primary_Category_two,
    Primary_Category_three,
    Primary_Category_four
}

export enum SKUName{
    Bhujia,
    Toothpaste,
    ToothBrush,
    
}