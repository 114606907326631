


















    import {Component, Vue, Watch, Prop, Emit} from 'vue-property-decorator';
    import DataTable from '@/components/common/Tables/DataTable.vue';
    import Snackbar from "@/components/common/Snackbar.vue";
    import Navbar from '@/components/common/Navbar.vue';
    import { ITableHeader} from '@/interfaces/common';
    import {PCSKUMappings} from '@/interfaces/CompetitorSurveyManagement'

    @Component({
        components:{
            DataTable,
            Navbar
        }       
    })
export default class CompetitorView extends Vue{
    @Prop() readonly ItemDetails!:PCSKUMappings;
    @Prop() readonly competitorName!:string;
        private tableHeaders:Array<ITableHeader>=[
        {
            text:'Primary Category', 
            value:'PC',
            sortable:true,
            default:'-'
        },
        {
            text:'SKUs',
            value:'Skus',
            sortable:false,
            default:'-'
        },
        ]
        @Emit("navigate")
        navigate(){

        }
        
    

}
